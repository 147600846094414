import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './customer.scss'; // Import SCSS file

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);
  const defaultImage = 'https://commentsapi.jetsonweb.com/uploads/default-profile.png'; // Default image URL

  useEffect(() => {
    axios.get('https://manageapi.jetsonweb.com/api/customers')
      .then((response) => {
        if (!response.data || response.data.length === 0) {
          setError('No customers found.');
          return;
        }

        const customersWithImages = response.data.map((customer) => ({
          ...customer,
          profile_image: customer.profile_image || defaultImage, // Use default if null
        }));

        setCustomers(customersWithImages);
      })
      .catch((err) => {
        console.error('Error fetching customers:', err);
        setError('Failed to load customers. Please try again.');
      });
  }, []);

  return (
    <div className="customer-container">
      <h2>Customers</h2>
      {error && <p className="error-message">{error}</p>}

      <div className="customer-grid">
        {customers.length === 0 && !error ? (
          <p>Loading customers...</p>
        ) : (
          customers.map((customer) => (
            <div key={customer.id} className="customer-card">
                <img
              src={customer.profile_image}
              alt={`${customer.name || 'Unknown'}'s profile`}
              className="customer-image"
              onError={(e) => { 
                e.target.src = defaultImage; // Fallback if image fails to load
              }}
            />
              <h3>{customer.name}</h3>
              <p><strong>Email:</strong> {customer.email}</p>
              <p><strong>Phone:</strong> {customer.phone}</p>
              <p><strong>Gender:</strong> {customer.gender}</p>
              <p><strong>Location:</strong> {customer.location}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
export default Customer;
