import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './job.scss';

const Job = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [requirements, setRequirements] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isOrderVisible, setIsOrderVisible] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    if (!isOrderVisible) {
      fetchJobs();
      fetchFiles();
    }
  }, [isOrderVisible]);

  const fetchJobs = async () => {
    setLoading(true); // Set loading state to true
    try {
      const response = await axios.get('https://manageapi.jetsonweb.com/api/jobs');
      setJobs(response.data.jobs); // Make sure to access the correct data structure
      setError(null); // Reset error state
    } catch (error) {
      console.error('Error fetching job postings:', error);
      setError('Failed to load jobs. Please try again.'); // Set error state
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await axios.get('https://manageapi.jetsonweb.com/api/files');
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !description || !requirements) {
      setMessage('All fields except file are required.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('requirements', requirements);
    if (file) {
      formData.append('file', file);
    }

    try {
      await axios.post('https://manageapi.jetsonweb.com/api/jobs', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMessage('Job vacancy uploaded successfully!');
      setTitle('');
      setDescription('');
      setRequirements('');
      setFile(null);
      fetchJobs(); // Refresh job list after upload
    } catch (error) {
      console.error('Error uploading job vacancy:', error);
      setMessage('Error uploading job vacancy. Please try again.');
    }
  };

  const handleDeleteJob = async (id) => {
    try {
      await axios.delete(`https://manageapi.jetsonweb.com/api/jobs/${id}`);
      setJobs(jobs.filter(job => job.id !== id)); // Remove from UI
      console.log(`Job ${id} deleted successfully`);
    } catch (error) {
      console.error("Error deleting job:", error.message);
    }
  };

  const handleFileDownload = (fileUrl) => {
    if (fileUrl) {
      window.open(fileUrl, '_blank'); // Open the file in a new tab
    } else {
      alert('File not available');
    }
  };

  
  return (
    <div className="job-container">
      <h1>Upload Job Vacancy</h1>

      <div className="button-container12">
        <button 
          className={`toggle-button12 ${isOrderVisible ? 'active' : ''}`} 
          onClick={() => setIsOrderVisible(true)}
        >
          Uploaded jobs
        </button>
        <button 
          className={`toggle-button12 ${!isOrderVisible ? 'active' : ''}`} 
          onClick={() => setIsOrderVisible(false)}
        >
          Sent History
        </button>
      </div>

      {isOrderVisible ? (
        <form onSubmit={handleSubmit} className="job-form">
          <label className="label">Job Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter job title"
            className="input"
          />

          <label className="label">Job Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter job description"
            className="textarea"
          />

          <label className="label">Requirements:</label>
          <textarea
            value={requirements}
            onChange={(e) => setRequirements(e.target.value)}
            placeholder="Enter job requirements"
            className="textarea"
          />

          <label className="label">Upload Job File (Optional):</label>
          <input type="file" onChange={handleFileChange} className="file-input" />

          <button type="submit" className="submit-button">Upload Job</button>

          {message && <div className="message">{message}</div>}
        </form>
      ) : (
        <div className="history-container">
        <h2>Sent History</h2>
        {loading ? (
          <p>Loading jobs...</p>
        ) : error ? (
          <p>{error}</p>
        ) : jobs.length > 0 ? (
          <div className="job-cards">
            {jobs.map((job) => (
              <div className="job-card" key={job.id}>
                <h3>{job.title}</h3>
                <p><strong>Description:</strong> {job.description}</p>
                <p><strong>Requirements:</strong> {job.requirements}</p>
                <p><strong>Created At:</strong> {new Date(job.created_at).toLocaleString()}</p>
      
                {job.file_path ? (
                  <p>
                    <strong>File:</strong>{" "}
                    <button
                      onClick={() =>
                        handleFileDownload(`https://manageapi.jetsonweb.com/${job.file_path}`)
                      }
                      className="download-button"
                    >
                      Download File
                    </button>
                  </p>
                ) : (
                  <p><strong>File:</strong> No File</p>
                )}
      
                <button 
                  className="delete-button" 
                  onClick={() => handleDeleteJob(job.id)}
                >
                  Delete Job
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>No job postings found.</p>
        )}
      </div>
      
      
      )}
    </div>
  );
};

export default Job;
