import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './analytics.scss';

const Analytics = () => {
  const [counts, setCounts] = useState({ approvedEmployees: 0, approvedPartners: 0, pendingPartners: 0 });
  const [view, setView] = useState('partners');
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [error, setError] = useState(''); // To handle errors
  const defaultImage = 'https://via.placeholder.com/150'; // Temporary image URL
  const [filterDate, setFilterDate] = useState('');
  const [filterCompany, setFilterCompany] = useState('');


  // Fetch counts for analytics
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await axios.get('https://manageapi.jetsonweb.com/api/analytics/counts');
        setCounts(response.data);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };
    fetchCounts();
  }, []);

  // Fetch partners data
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get('https://manageapi.jetsonweb.com/api/partners');
        const sanitizedPartners = response.data.map((partner) => {
          let imageUrl = partner.profile_image
            ? partner.profile_image.startsWith('http')
              ? partner.profile_image.replace(/([^:])\/{2,}/g, '$1/') // Remove duplicate slashes, except after protocol
                  .replace(/\/uploads\/uploads\//, '/uploads/') // Fix duplicate "uploads/uploads"
              : `https://thepartnersapi.jetsonweb.com/uploads/${partner.profile_image.replace(/^\/+/, '')}`
            : 'https://thepartnersapi.jetsonweb.com/uploads/default-profile.png';
    
          console.log('Partner:', partner.full_name, 'Image URL:', imageUrl); // Debugging
    
          return {
            ...partner,
            profile_image: imageUrl,
          };
        });
    
        setPartners(sanitizedPartners);
      } catch (error) {
        console.error('Error fetching partners:', error);
      }
    };
    
    if (view === 'partners') fetchPartners();
  }, [view]);
  
  

  // Update partner status
  const updatePartnerStatus = async (fullName, newStatus) => {
    const validStatuses = ['pending', 'approved', 'declined', 'suspended'];
  
    if (!validStatuses.includes(newStatus)) {
      console.error('Invalid status:', newStatus);
      return;
    }
  
    try {
      const payload = { fullName, newStatus };
  
      await axios.put('https://manageapi.jetsonweb.com/api/users/updateStatus', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      // Update local state with new status
      setPartners((prev) =>
        prev.map((partner) =>
          partner.full_name === fullName ? { ...partner, isApproved: newStatus } : partner
        )
      );
    } catch (error) {
      console.error('Error updating partner status:', error);
    }
  };
  
  


  // Fetch partner details
  const handlePartnerClick = async (id) => {
    console.log('Fetching partner details for ID:', id);  // Log the ID
    try {
      const response = await axios.get(`https://manageapi.jetsonweb.com/api/partners/${id}`);
      const partnerDetails = {
        ...response.data,
        profile_image: response.data.profile_image
          ? `https://thepartnersapi.jetsonweb.com${response.data.profile_image}`
          : defaultImage,
      };
      setSelectedPartner(partnerDetails);
    } catch (error) {
      console.error('Error fetching partner details:', error);
      setError('Could not fetch partner details');
    }
  };
  
  // Close popups
  const closePartnerPopup = () => {
    setSelectedPartner(null);
  };
  
   

  return (
    <div className="analytics-container">
      <h1>Analytics</h1>

      {/* Counts Section */}
      <div className="counts-row">
        <div className="count-box">
          <h3>Employees</h3>
          <p>{counts.approvedEmployees || 0}</p>
        </div>
        <div className="count-box">
          <h3>Partners</h3>
          <p>{counts.approvedPartners || 0}</p>
        </div>
        <div className="count-box">
          <h3>Pending Partners</h3>
          <p>{counts.pendingPartners || 0}</p>
        </div>
      </div>

      {/* Toggle View Buttons */}
      <div className="buttons-row">
        <button
          onClick={() => setView('partners')}
          className={view === 'partners' ? 'active' : ''}
        >
          Partners
        </button>
      </div>

      {/* Partners List */}
      <div className="list-container">
  {view === 'partners' && partners.length > 0 ? (
    partners.map((partner) => (
      <div key={partner.id} className="user-card">
        <img
          src={partner.profile_image || 'https://thepartnersapi.jetsonweb.com/uploads/default-profile.png'}
          alt={`${partner.full_name || 'Unknown'}'s profile`}
          className="user-image"
        />

        <div className="user-info">
          <h4 onClick={() => handlePartnerClick(partner.id)}>
            {partner.full_name || 'Unknown'}
          </h4>

          <div className="status-dropdown">
            <select
              value={partner.isApproved}
              onChange={(e) => updatePartnerStatus(partner.full_name, e.target.value)}
              className="status-select"
            >
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="suspended">Suspended</option>
              <option value="declined">Declined</option>
            </select>
          </div>

          <button
            onClick={() => handlePartnerClick(partner.id)}
            className="view-profile-button"
          >
            View Profile
          </button>
        </div>
      </div>
    ))
  ) : (
    <p>No partners available</p>
  )}
</div>



      {/* Partner Popup */}
      {selectedPartner && (
        <div className="popup">
          <div className="popup-content">
            <span className="close-button" onClick={closePartnerPopup}>
              &times;
            </span>
            <h3>{selectedPartner.full_name}</h3>
            <img
              src={selectedPartner.profile_image}
              alt={`${selectedPartner.full_name}'s profile`}
              className="popup-image"
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                marginBottom: '10px',
              }}
            />
            <p>Company: {selectedPartner.company_name}</p>
            <p>Email: {selectedPartner.email}</p>
            <p>Username: {selectedPartner.username}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
