import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './upload.scss'; // Ensure this path is correct

const Upload = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [announcementDate, setAnnouncementDate] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isOrderVisible, setIsOrderVisible] = useState(true);
  const [announcements, setAnnouncements] = useState([]); // Added state for announcements
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!title || !description || !announcementDate || !file || !selectedRole) {
      setMessage('Please fill in all fields and upload a file.');
      return;
    }
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('announcement_date', announcementDate);
    formData.append('file', file);
    formData.append('role', selectedRole);
  
    try {
      const response = await axios.post('https://manageapi.jetsonweb.com/api/uploadfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setMessage('File uploaded successfully!');
      console.log(response.data);
  
      setTitle('');
      setDescription('');
      setAnnouncementDate('');
      setFile(null);
      setSelectedRole('');
      // Refresh announcements after upload
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://manageapi.jetsonweb.com/api/files/${id}`, {
        method: "DELETE",
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete file");
      }
  
      setFiles(files.filter(file => file.id !== id)); // Remove from UI
      console.log(`File ${id} deleted successfully`);
    } catch (error) {
      console.error("Error deleting file:", error.message);
    }
  };
  
  
  
  
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get('https://manageapi.jetsonweb.com/api/files');
        setFiles(response.data.files);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, []);

  useEffect(() => {
    axios.get('https://manageapi.jetsonweb.com/api/roles')
      .then(response => {
        if (response.data && Array.isArray(response.data.roles)) {
          setRoles(response.data.roles);
        } else {
          setRoles([]);
        }
      })
      .catch(error => {
        console.error('Error fetching roles:', error);
        setRoles([]);
      });

     // Fetch announcements on component mount
  }, []);


  const handleFileDownload = (fileUrl) => {
    if (fileUrl) {
      window.open(fileUrl, '_blank'); // Open the file in a new tab
    } else {
      alert('File not available');
    }
  };

  return (
    <div className="container">
      <h1>Upload schedules</h1>

      <div className="button-container12">
        <button 
          className={`toggle-button12 ${isOrderVisible ? 'active' : ''}`} 
          onClick={() => setIsOrderVisible(true)}
        >
          uploaded
        </button>
        <button 
          className={`toggle-button12 ${!isOrderVisible ? 'active' : ''}`} 
          onClick={() => setIsOrderVisible(false)}
        >
          Sent history
        </button>
      </div>

      {isOrderVisible && (
        <form onSubmit={handleSubmit} className="form">
          <label className="label">Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter the title of the announcement"
            className="input"
            required
          />

          <label className="label">Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Provide a brief description of the announcement"
            className="textarea"
            required
          />

          <label className="label">Announcement Date:</label>
          <input
            type="date"
            value={announcementDate}
            onChange={(e) => setAnnouncementDate(e.target.value)}
            className="input"
            required
          />

          <label>Select Employee Role:</label>
          <select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
            <option value="">-- Select a Role --</option>
            {roles.map((role, index) => (
              <option key={index} value={role.role_name}>
                {role.role_name}
              </option>
            ))}
          </select>

          <label className="label">Upload File:</label>
          <input
            type="file"
            accept=".pdf,.xls,.xlsx,.doc,.docx,.jpg,.jpeg,.png"
            onChange={handleFileChange}
            className="fileInput"
            required
          />

          <button type="submit" className="submitButton">Upload</button>
          {message && <div className="message">{message}</div>}
        </form>
      )}

      {/* Display Announcements */}
     <div className={`announcements-container section return-section ${!isOrderVisible ? 'active' : ''}`}>
  <h2>Uploaded Files</h2>
  {files.length > 0 ? (
    <div className="file-cards">
      {files.map((file) => (
        <div 
          className="file-card" 
          key={file.id} 
          role={file.role || "listitem"}  // Dynamically setting role based on file.role
          aria-labelledby={`file-title-${file.id}`} 
          aria-describedby={`file-description-${file.id}`}
        >
          <h3 id={`file-title-${file.id}`}>{file.title}</h3>
          <p id={`file-description-${file.id}`}>{file.description}</p>
          <p><strong>Date:</strong> {new Date(file.announcement_date).toLocaleString()}</p>
          
          
          {/* Display the role if available */}
          {file.role && (
            <p><strong>Role:</strong> {file.role}</p>
          )}

          {file.file_path && (
            <button 
              onClick={() => handleFileDownload(file.file_path)} // Call the download handler
              className="download-button"
              aria-label={`Download ${file.title}`}
            >
              Download File
            </button>
          )}

          <button 
            className="delete-btn" 
            onClick={() => handleDelete(file.id)} 
            aria-label={`Delete ${file.title}`}
          >
            Delete
          </button>
        </div>
      ))}
    </div>
  ) : (
    <p className="no-files">No files uploaded yet.</p>
  )}
</div>





    </div>
  );
};

export default Upload;
