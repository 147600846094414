import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Employeelist = () => {
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch employees from the API
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('https://manageapi.jetsonweb.com/api/employeeslist');
        setEmployees(response.data); // Ensure isApproved is included in the API response
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || 'Failed to fetch employees');
        setLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  // Update employee approval status
  const updateEmployeeStatus = async (employeeId, newStatus) => {
    try {
      console.log("Updating Status for Employee:", employeeId, "New Status:", newStatus); // Debugging line
  
      const response = await axios.put('https://manageapi.jetsonweb.com/api/employees/updateStatus', {
        employeeId,
        newStatus: newStatus.toLowerCase() // Ensure the status is in lowercase
      });
  
      console.log("Status updated successfully:", response.data);
  
      // Update the employee status in the UI without refreshing
      setEmployees((prevEmployees) =>
        prevEmployees.map((employee) =>
          employee.id === employeeId
            ? { ...employee, isApproved: newStatus.toLowerCase() }
            : employee
        )
      );
    } catch (error) {
      console.error("Error updating status:", error.response ? error.response.data : error.message);
    }
  };
  

  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>Employee List</h1>

      {loading ? (
        <p style={{ textAlign: 'center' }}>Loading employees...</p>
      ) : error ? (
        <p style={{ color: 'red', textAlign: 'center' }}>Error: {error}</p>
      ) : employees.length > 0 ? (
        <div
          style={{
            height: '70vh', // Adjust the height as needed
            overflowY: 'auto',
            border: '1px solid #ddd',
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
              gap: '20px',
            }}
          >
            {employees.map((employee) => (
              <div
                key={employee.id}
                style={{
                  border: '1px solid #ddd',
                  borderRadius: '10px',
                  padding: '20px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  textAlign: 'center',
                }}
              >
                <img
                  src={`https://manageapi.jetsonweb.com/${employee.profile_image.replace(/\\/g, '/')}`}
                  alt={`${employee.full_name || 'N/A'}'s profile`}
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    marginBottom: '10px',
                  }}
                />

                <h3 style={{ margin: '10px 0' }}>{employee.full_name || 'N/A'}</h3>
                <p>Email: {employee.email || 'N/A'}</p>
                <p>Phone: {employee.phone || 'N/A'}</p>
                <p>Username: {employee.username || 'N/A'}</p>
                <p>Role: {employee.role || 'N/A'}</p> {/* Display the role */}
                <p>Employment Date:{' '}
                  {employee.employment_date
                    ? new Date(employee.employment_date).toLocaleDateString()
                    : 'N/A'}
                </p>
                <p>Password: {employee.password || 'N/A'}</p> 
                <p>Status: {employee.isApproved === 'approved' ? 'Approved' : 'Declined'}</p>
 {/* Updated to string values */}

                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <button
  onClick={() => updateEmployeeStatus(employee.id, 'approved')}
  style={{
    backgroundColor: 'green',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: employee.isApproved === 'approved' ? 'not-allowed' : 'pointer',
    opacity: employee.isApproved === 'approved' ? 0.5 : 1,
  }}
  disabled={employee.isApproved === 'approved'} // Disable when already approved
>
  Approve
</button>

<button
  onClick={() => updateEmployeeStatus(employee.id, 'declined')}
  style={{
    backgroundColor: 'red',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: employee.isApproved === 'declined' ? 'not-allowed' : 'pointer',
    opacity: employee.isApproved === 'declined' ? 0.5 : 1,
  }}
  disabled={employee.isApproved === 'declined'} // Disable when already declined
>
  Decline
</button>

                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>No employees found</p>
      )}
    </div>
  );
};

export default Employeelist;
