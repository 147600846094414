import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './feedback.scss'; // Import SCSS file

const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    // Fetch feedbacks from the API
    axios.get('https://manageapi.jetsonweb.com/api/feedbacks') // Adjust the API endpoint accordingly
      .then((response) => {
        setFeedbacks(response.data); // Set the fetched feedbacks to state
      })
      .catch((error) => {
        console.error('Error fetching feedbacks:', error);
      });
  }, []);

  return (
    <div className="feedback-container">
      <h2>Feedbacks</h2>
      <div className="feedback-list">
        {feedbacks.length === 0 ? (
          <p>No feedback available.</p>
        ) : (
          feedbacks.map((feedback) => (
            <div key={feedback.id} className="feedback-item">
              <h3><strong>Name: </strong>{feedback.name}</h3>
              <p><strong>Description: </strong>{feedback.description}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Feedback;
