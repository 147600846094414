import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './announcement.scss';

const AnnouncementUpload = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [announcements, setAnnouncements] = useState([]);
  const [isOrderVisible, setIsOrderVisible] = useState(true); 
  

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get('https://manageapi.jetsonweb.com/api/roles');
        setRoles(response.data.roles);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleFileRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!title || !description || !date || !role) {
      setMessage('Please fill in all required fields.');
      return;
    }
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('announcement_date', date);
    formData.append('role', role);
  
    if (files.length > 0) {
      files.forEach((file) => {
        formData.append('files', file);
      });
    }
  
    try {
      const response = await axios.post('https://manageapi.jetsonweb.com/api/announcements', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMessage('Announcement uploaded successfully!');
      setTitle('');
      setDescription('');
      setDate('');
      setRole('');
      setFiles([]);
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading announcement:', error);
      setMessage('Error uploading announcement. Please try again.');
    }
  };

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('https://manageapi.jetsonweb.com/api/announcements');
        setAnnouncements(response.data.announcements);
      } catch (error) {
        console.error('Error fetching announcements:', error);
        setMessage('Error fetching announcements. Please try again later.');
      }
    };

    fetchAnnouncements();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://manageapi.jetsonweb.com/api/announcements/${id}`);
      setMessage('Announcement deleted successfully!');
      setAnnouncements(announcements.filter((announcement) => announcement.id !== id));
    } catch (error) {
      console.error('Error deleting announcement:', error);
      setMessage('Error deleting announcement. Please try again.');
    }
  };
  const handleFileDownload = (fileUrl) => {
    if (fileUrl) {
      window.open(fileUrl, '_blank'); // Open the file in a new tab
    } else {
      alert('File not available');
    }
  };
  return (
    <div className="container">
      {/* Toggle Buttons */}
      <div className="button-container12">
        <button 
          className={`toggle-button12 ${isOrderVisible ? 'active' : ''}`} 
          onClick={() => setIsOrderVisible(true)}
        >
          Upload Announcement
        </button>
        <button 
          className={`toggle-button12 ${!isOrderVisible ? 'active' : ''}`} 
          onClick={() => setIsOrderVisible(false)}
        >
          Sent History
        </button>
      </div>

      {/* Conditionally Render Sections */}
      {isOrderVisible ? (
        <div>
          <h1>Upload Announcement</h1>
          <form onSubmit={handleSubmit} className="form">
            <label className="label">Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter the title of the announcement"
              className="input"
            />

            <label className="label">Description:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Provide a brief description of the announcement"
              className="textarea"
            />

            <label className="label">Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="input"
            />

            <label className="label">Role:</label>
            <select 
              value={role} 
              onChange={(e) => setRole(e.target.value)} 
              className="input"
            >
              <option value="">Select Role</option>
              {roles.map((roleItem, index) => (
                <option key={index} value={roleItem.role_name}>
                  {roleItem.role_name}
                </option>
              ))}
            </select>

            <label className="label">Upload Files:</label>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className="fileInput"
            />
            <div className="file-list">
              {files.map((file, index) => (
                <div key={index} className="file-item">
                  {file.name} <button type="button" onClick={() => handleFileRemove(file.name)}>Remove</button>
                </div>
              ))}
            </div>

            <button type="submit" className="submitButton">Upload</button>
            {message && <div className="message">{message}</div>}
          </form>
        </div>
      ) : (
        <div className="announcements-container">
        <h2>Uploaded Files</h2>
        {announcements.length > 0 ? (
          <div className="file-cards">
            {announcements.map((file) => (
              <div className="file-card" key={file.id}>
                <h3>{file.title}</h3>
                <p>{file.description}</p>
                <p><strong>Date:</strong> {new Date(file.announcement_date).toLocaleString()}</p>
                
                {file.role && <p><strong>Role:</strong> {file.role}</p>}
                
                {file.file_paths && (
                  <button 
                    onClick={() => handleFileDownload(file.file_paths)} 
                    className="download-button"
                  >
                    Download File
                  </button>
                )}
                
                <button onClick={() => handleDelete(file.id)} className="delete-button12">
                  Delete
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="no-files">No files uploaded yet.</p>
        )}
      </div>
      
      )}
    </div>
  );
};

export default AnnouncementUpload;
