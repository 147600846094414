import React, {  useState, useEffect  } from 'react';
import axios from 'axios';
import './addemployee.scss';

const Addemployee = () => {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // New state for image preview
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [employmentDate, setEmploymentDate] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [message1, setMessage1] = useState('');
  const [roleName, setRoleName] = useState('');
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');

  useEffect(() => {
    axios.get('https://manageapi.jetsonweb.com/api/roles')
      .then(response => {
        setRoles(response.data.roles);
      })
      .catch(error => {
        console.error('Error fetching roles:', error);
      });
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'employmentDate':
        setEmploymentDate(value);
        break;
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };


  const handlerolls = async (e) => {
    e.preventDefault();
  
    if (!roleName.trim()) {
      setMessage1('Please enter a role name.');
      return;
    }
  
    try {
      const response = await axios.post('https://manageapi.jetsonweb.com/api/roles', { role_name: roleName });
      
      // ✅ Display success message only if status is 201
      if (response.status === 201) {
        setMessage1(response.data.message);
        setRoleName('');
      }
    } catch (error) {
      console.error('Error adding role:', error);
  
      if (error.response) {
        if (error.response.status === 409) {
          setMessage1('Role already exists.');
        } else {
          setMessage1('Failed to add role. Try again.');
        }
      } else {
        setMessage1('Server error. Please check your connection.');
      }
    }
  };
  


  // Handle image change
  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setImagePreview(URL.createObjectURL(e.target.files[0])); // Set preview URL
    }
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('full_name', name);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('employment_date', employmentDate);
    formData.append('username', username);
    formData.append('password', password);
    formData.append('profile_image', imageFile);
    formData.append('role', selectedRole);  // Add the selected role to form data

    try {
      const response = await axios.post('https://manageapi.jetsonweb.com/api/employees', formData);
      setMessage('Employee added successfully!');
      console.log(response.data);
      setImagePreview(null); // Clear the preview after successful submission
    } catch (error) {
      console.error('There was an error adding the employee!', error);
      setMessage('Error adding employee. Please try again.');
    }
  };

  return (
    <div className="add-employee-form-container">
      <h2>Add New Employee</h2>
      <form onSubmit={handleSubmit} className="add-employee-form">
      {/* Image Uploader */}
      <div className="form-group">
        <label htmlFor="image">Profile Picture</label>
        {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview" />}
        <input
          type="file"
          id="image"
          accept="image/*"
          onChange={handleImageChange}
        />
      </div>

      {/* Name */}
      <div className="form-group">
        <label htmlFor="name">Full Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={handleInputChange}
          placeholder="Enter employee's full name"
          required
        />
      </div>

      {/* Phone Number */}
      <div className="form-group">
        <label htmlFor="phone">Phone Number</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={phone}
          onChange={handleInputChange}
          placeholder="Enter phone number"
          required
        />
      </div>

      {/* Email */}
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleInputChange}
          placeholder="Enter email address"
          required
        />
      </div>

      {/* Date of Employment */}
      <div className="form-group">
        <label htmlFor="employmentDate">Date of Employment</label>
        <input
          type="date"
          id="employmentDate"
          name="employmentDate"
          value={employmentDate}
          onChange={handleInputChange}
          required
        />
      </div>

      {/* Username */}
      <div className="form-group">
        <label htmlFor="username">Username</label>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={handleInputChange}
          placeholder="Enter username"
          required
        />
      </div>

      {/* Password */}
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="text"
          id="password"
          name="password"
          value={password}
          onChange={handleInputChange}
          placeholder="Enter password"
          required
        />
      </div>

      {/* Role Dropdown */}
      <div className="form-group">
        <label htmlFor="role">Role</label>
        <select
          id="role"
          name="role"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          required
        >
          <option value="">Select a role</option>
          {roles.map((role, index) => (
            <option key={index} value={role.role_name}>
              {role.role_name}
            </option>
          ))}
        </select>
      </div>

      {/* Submit Button */}
      <button type="submit" className="submit-btn">Add Employee</button>
    </form>

      {/* Success/Error Message */}
      {message && <div className="message">{message}</div>}

      <div className="add-role-form-container">
      <h2>Add New Role</h2>
      <form onSubmit={handlerolls} className="add-role-form">
        <div className="form-group">
          <label htmlFor="role">Role Name</label>
          <input
            type="text"
            id="role"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-btn">Add Role</button>
      </form>
      {message1 && <div className="message">{message1}</div>}
    </div>


    </div>
    
  );
};

export default Addemployee;
