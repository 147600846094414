import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './news&blogs.scss'; // Ensure this path is correct

const NewsUpload = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [publishDate, setPublishDate] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderVisible, setIsOrderVisible] = useState(true);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    try {
      const response = await axios.get('https://manageapi.jetsonweb.com/api/news');
      setAnnouncements(response.data);
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleVideoChange = (event) => {
    setVideo(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!title || !description || !publishDate) {
      setMessage('Please fill in all required fields.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('publish_date', publishDate);

    if (image) formData.append('image', image);
    if (video) formData.append('video', video);

    try {
      setIsLoading(true);
      await axios.post('https://manageapi.jetsonweb.com/api/news_blogs', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setMessage('News upload successful!');
      setTitle('');
      setDescription('');
      setPublishDate('');
      setImage(null);
      setVideo(null);
      fetchAnnouncements();
    } catch (error) {
      setMessage('Error uploading news. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (fileId) => {
    try {
      await axios.delete(`https://manageapi.jetsonweb.com/api/news_blogs/${fileId}`);
      setAnnouncements(announcements.filter(file => file.id !== fileId));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  return (
    <div className="news-upload-container">
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader">
            <span className="loader-text">loading</span>
            <span className="load"></span>
          </div>
        </div>
      )}

      <div className="button-container12">
        <button 
          className={`toggle-button12 ${isOrderVisible ? 'active' : ''}`} 
          onClick={() => setIsOrderVisible(true)}
        >
          Upload News
        </button>
        <button 
          className={`toggle-button12 ${!isOrderVisible ? 'active' : ''}`} 
          onClick={() => setIsOrderVisible(false)}
        >
          Sent History
        </button>
      </div>

      {isOrderVisible ? (
        <>
          <h1>Upload News & Blogs</h1>
          <form onSubmit={handleSubmit} className="news-upload-form">
            {image && <img src={URL.createObjectURL(image)} alt="Preview" className="previewImage" />}
            <label className="label">Image:</label>
            <input type="file" accept="image/*" onChange={handleImageChange} className="fileInput" />

            {video && <video width="200" height="200" controls src={URL.createObjectURL(video)} />}
            <label className="label">Video:</label>
            <input type="file" accept="video/*" onChange={handleVideoChange} className="fileInput" />

            <label className="label">Title:</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter title" className="input" required />

            <label className="label">Description:</label>
            <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Write the content here" className="textarea" required />

            <label className="label">Publish Date:</label>
            <input type="date" value={publishDate} onChange={(e) => setPublishDate(e.target.value)} className="input" required />

            <button type="submit" className="submitButton">Upload</button>
            {message && <div className="message">{message}</div>}
          </form>
        </>
      ) : (
        <div className="announcements-container">
          <h2>Uploaded Files</h2>
          {announcements.length > 0 ? (
            <div className="file-cards">
              {announcements.map((file) => (
                <div className="file-card" key={file.id}>
                  <h3>{file.title}</h3>
                  <p><strong>Publish Date:</strong> {new Date(file.publish_date).toLocaleString()}</p>
                  <button onClick={() => handleDelete(file.id)} className="delete-button12">
                    Delete
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-files">No files uploaded yet.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsUpload;
